import React from 'react';
import { colors } from '../styles/colorPalette';
import "../styles/components/footer.css";
import "../styles/components/plane.css";
import content from '../locales/navigation-options.js';
import { Link } from 'react-router-dom';

const Footer = ({ language }) => {
    return (
        <footer className="container-fluid footer text-left px-0 mt-auto" style={{ backgroundColor: colors.grey.dark, bottom: 0 }}>
            <div className="container-fluid flex-row flex-wrap d-flex align-items-center justify-content-between px-4">
                <div className="flex-row flex-wrap d-flex align-items-start justify-content-between mb-4">
                    <div className="d-flex pt-4">
                        <img src="/images/logo-white-small.png" alt="Company Logo Footer" className="logo-footer" />
                    </div>
                    <div className="d-flex flew-row flex-wrap flex-fill mx-2 pt-1">
                        <div className="d-flex flex-fill pt-4 mx-4">

                            <ul className="list-unstyled d-flex flex-column" style={{ color: colors.grey.light }}>
                                <li>
                                    <a className="contact-info" href="mailto:iroda@kob-flex.hu">iroda@kob-flex.hu</a>
                                </li>
                                <li className="contact-info">
                                    +36 33 504 410
                                </li>
                                <li className="contact-info">
                                    +36 33 504 411
                                </li>
                            </ul>
                        </div>
                        <div className="contact-info d-flex flex-fill pt-4 mx-4">

                            <ul className="list-unstyled d-flex flex-column">
                                <li>
                                    Kossuth Lajos 220/A
                                </li>
                                <li>
                                    Nyergesújfalu, HU
                                </li>
                                <li>
                                    2536
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex flex-fill pt-4 mx-4">

                            <ul className="list-unstyled d-flex flex-column">
                                <li>
                                    <Link className="text-body" to={`/${language}/about`}>{content[language]?.about}</Link>
                                </li>
                                <li>
                                    <Link className="text-body" to={`/${language}/portfolio`}>{content[language]?.portfolio}</Link>
                                </li>
                                <li>
                                    <Link className="text-body" to={`/${language}/contact`}>{content[language]?.contact}</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex flex-fill pt-4 mx-4">

                            <ul className="list-unstyled d-flex flex-column">
                                <li>
                                    <Link className="text-body" to={`/eu-policy`}>{content[language]?.terms}</Link>
                                </li>
                                <li>
                                    <Link className="text-body" to={`/${language}/legal`}>{content[language]?.legal}</Link>
                                </li>
                                <li>
                                    <Link className="text-body" to={`/docs`}>{content[language]?.docs}</Link>
                                </li>
                            </ul>
                        </div>
                    </div>


                </div>
                <div className="d-flex flex-row align-items-center justify-content-start mx-5 gap-3" style={{ maxWidth: "15%"}}>
                    <Link className="plane d-flex" to={`/docs`} style={{ maxWidth: "50%", minWidth: "80px" }}><img className=" img-fluid flex-fill" alt="iqnetlogo" src="/images/third-party/iqnet.png" /></Link>
                    <img className=" plane img-fluid" alt="siemenslogo" src="/images/third-party/siemens.jpg" style={{ maxWidth: "50%", minWidth: "80px" }} />
                </div>
            </div>
            <div className="container-fluid bottomline-footer text-center m-0 p-1" style={{ backgroundColor: colors.black, color: colors.white }}>
                © 2024 Copyright:
                <a href="https://kob-flex.hu/" style={{ color: colors.white }}> KOB-FLEX KFT.</a>
            </div>
        </footer>
    );
};

export default Footer;