export const content = {
    dairy: {
        en: "Dairy",
        hu: "Tejipar"
    },
    beer: {
        en: "Beer",
        hu: "Söripar"
    },
    chemical: {
        en: "Chemicals",
        hu: "Vegyipar"
    },
    medicine: {
        en: "Medicine",
        hu: "Gyógyszeripar"
    },
    soda: {
        en: "Beverages",
        hu: "Üdítők"
    },
    water: {
        en: "Mineral water",
        hu: "Ásványvíz"
    },
    construction: {
        en: "Construction",
        hu: "Építőipar"
    },
    automotive: {
        en: "Automotive",
        hu: "Autóipar"
    }
}