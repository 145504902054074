import React, { useEffect, useState } from "react";
import "../../styles/components/plane.css";
import { content } from "../../locales/contact";
import { colors } from "../../styles/colorPalette";

function SmallPlane({ lang }) {
    const [direction, setDirection] = useState("");

    useEffect(() => {
        const handleResize = () => {
            window.innerWidth < 984 ? setDirection("flex-column") : setDirection("flex-row");
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <section className="card plane container-fluid align-items-left mb-4">
            <div className={`d-flex ${direction}`} >
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5977.895604531176!2d18.556973863028553!3d47.75922377344509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476a5dd0bf631f5f%3A0x65e9c827e4ff7378!2sKob-Flex%20Kft.!5e0!3m2!1shu!2shu!4v1709636308410!5m2!1shu!2shu"
                    style={{ "border": 0, width: "100%" }}
                    className="d-flex"
                    allowFullScreen=""
                    title="Google Maps"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                <div className="d-flex">
                    <div className="card-body">
                        <table className="table table-hover">
                            <tbody>
                                <tr className={direction.includes("column") ? `d-flex ${direction}` : ""}>
                                    <td style={{ color: colors.grey.dark }}>{content[lang].openingName}</td>
                                    <td>{content[lang].openingValue}</td>
                                </tr>
                                <tr className={direction.includes("column") ? `d-flex ${direction}` : ""}>
                                    <td style={{ color: colors.grey.dark }}>{content[lang].primaryPhoneName}</td>
                                    <td>{content[lang].primaryPhoneValue}</td>
                                </tr>
                                <tr className={direction.includes("column") ? `d-flex ${direction}` : ""}>
                                    <td style={{ color: colors.grey.dark }}>{content[lang].primaryEmailName}</td>
                                    <td>{content[lang].primaryEmailValue}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div></div>
            </div>
        </section>
    );
}

export default SmallPlane;