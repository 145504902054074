import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { colors } from '../styles/colorPalette';
import "../styles/components/navigation.css";
import content from "../locales/navigation-options.js";

function Navigation({ language, logo, flexClass }) {
  const oppositeLang = language === "hu" ? "en" : "hu";
  const { pathname } = useLocation();
  const subPath = pathname.replace(`/${language}`, "");

  const [disabled, setDisabled] = useState("");

  useEffect(() => {
    if (pathname.includes("eu-policy") || pathname.includes("docs")) {
      setDisabled("disabled");
    } else {
      setDisabled("");
    }
  }, [language, pathname]);

  return (
    <nav className={`navbar navbar-expand-lg ${flexClass} flex-grow-1`} style={{ height: "100%" }}>
      <div className={`container-fluid ${flexClass}`} style={{ height: "100%" }}>
        <div className={`collapse navbar-collapse ${flexClass} px-3`} style={{ height: "100%" }}>
          <div className={`navbar-brand logo-container align-items-center justify-content-between ${flexClass}`}>
            <Link className={`d-flex`} to={`/${language}`} style={{ height: "100%" }}>
              <img src={`/images/${logo}.png`} alt="Company Logo" className={`logo flex-fill ${logo === "icon" ? "p-3" : ""}`} />
            </Link>
            <img  src={`/images/third-party/siemens.jpg`} alt="Company Logo" className={`img-fluid overflow-hidden mx-5 ${logo === "icon" ? "d-none" : ""}`} style={{ maxHeight: "50px" }}/>
          </div>

          <div className={`options collapse navbar-collapse mx-1 ${flexClass}`}>
            <ul className="navbar-nav navbar-main mx-4">
              <li className="nav-item mx-3"><Link className="nav-link" style={{ color: colors.black, fontSize: "150%", textAlign: "center" }} to={`/${language}/about`}>{content[language]?.about}</Link></li>
              <li className="nav-item mx-3"><Link className="nav-link" style={{ color: colors.black, fontSize: "150%", textAlign: "center" }} to={`/${language}/portfolio`}>{content[language]?.portfolio}</Link></li>
              <li className="nav-item mx-3"><Link className="nav-link" style={{ color: colors.red, fontSize: "150%", textAlign: "center" }} to={`/${language}/contact`}>{content[language]?.contact}</Link></li>
            </ul>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item mx-3"><Link className={`nav-link ${disabled}`} style={{ color: colors.grey.dark, fontSize: "1em" }} to={`/${oppositeLang}${subPath}`}>{oppositeLang.toUpperCase()}</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>

  );
}

export default Navigation;