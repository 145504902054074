const content = {
    en: [
        {
            title: "Electrical Engineering",
            text: ["Our services include the preparation of *electrical and *functional plans for *complex industrial production control, data collection and monitoring systems.[line divide]Our engineers support our customers and lead construction teams with decades of experience and deep understanding of industrial requirements and *innovative solutions.[line divide]We also provide documentation according to *ATEX standards.[line divide]We manufacture low- and high-current *control cabinets in-house.[line divide]As a contracted *Siemens *system *integrator, besides Siemens products, we use non-Siemens components exclusively from upper-end suppliers (e.g.: Rittal, Ilinox, Phoenic Contact, Schneider Electric, Wago, Weidmüller)."],
        },
        {
            title: "Industrial Automation",
            text: ["Our company has developed an extensive industrial portfolio inside and outside the European Union in the past twenty years. A significant part of our clients are *food and *pharmaceutical companies and technology suppliers. In addition, we have also been active in *construction *materials production, *chemical production, and other *FMCG production areas.[line divide]As a Siemens system integrator, we develop *S7-1200/1500 *PLC and *WinCC *SCADA based production automation systems.[line divide]On demand, we can aptly support clients in the development of *Omron and *Rockwell systems as well.[line divide]We also have extensive experience in migrating the now obsolete *SattControl systems to Siemens.[line divide]We are equipped to cater to special service requests (e.g., *safety ).[line divide]Our company also offers cutting-edge *robotisation solutions."],
        },
        {
            title: "OT Network Engineering",
            text: ["We offer OT network planning and engineering solutions in accordance with the latest industrial trends and standards, using primarily *Siemens products.[line divide]Our expertise ranges from basic star-shaped network topologies to *complex *optical *networks.[line divide]We can furthermore install *VPN-based remote access via Siemens platforms in order to ensure operational security of production.[line divide]Our company also offers expert customer service on matters regarding the *EU *NIS2 Directive in order to ensure regulatory compliance as well as operational resilience."],
        },
        {
            title: "Data Collection & Processing",
            text: ["We upgrade our SCADA systems continuously in accordance with the ever-expanding industrial trends and standards as well as user requirements.[line divide]In addition to production monitoring and measurement data collection, efficient processing and visualisation of the data at hand is indispensable. We address these challenges by providing extended event monitoring in our *SCADA systems, *OEE solutions for data collection, and *energy *optimisation modules.[line divide]Our applications are developed primarily on Siemens platforms, incl. WinCC V8, WinCC Unified, Industrial Edge, etc."],
        },
        {
            title: "Repair & Maintenance",
            text: ["The electrical industry has advanced significantly, resulting in *minimal *component *failure *rates.[line divide]Regular inspection and maintenance remain crucial for uninterrupted operation.[line divide]We offer customizable maintenance contracts and remote software support.[line divide]Our services include training local maintenance personnel for quick on-site issue resolution, minimizing downtime and optimizing system performance."],
        }
    ],
    hu: [
        {
            title: "Villamos tervezés",
            text: ["Szolgáltatásunk kiterjed ipari *komplex termelésirányító, adatgyűjtő és felügyeleti rendszerek *villamos- és *funkcióterveinek elkészítésére.[line divide]Évtizedes tapasztalattal rendelkező tervező kollégáink az ipari követelményekre és *innovatív megoldásokra építve vezetik és támogatják Ügyfeleinket és a kivitelező csapatainkat.[line divide]Tervdokumentációkat készítünk *ATEX előírások alapján is.[line divide]Gyenge- és erősáramú *kapcsolószekrények *gyártását saját telephelyünkön végezzük.[line divide]Szerződött *Siemens *rendszerintegrátorként a Siemens termékeinek használata mellett kizárólag a magas minőségű termékeket gyártó beszállítók alkatrészeit használjuk (pl.: Rittal, Ilinox, Phoenic Contact, Schneider Electric, Wago, Weidmüller)."],
        },
        {
            title: "Ipari automatizálás",
            text: ["Több, mint 20 éves tapasztalattal és kiterjedt projekt portfólióval büszkélkedünk az Európai Unión belül és kívül is. Megbízóink jelentős része *élelmiszer- és *gyógyszeripari vállalat, illetve technológiai beszállító. Mindemelett az *építőanyaggyártás, *vegyipar, egyéb *FMCG gyártási területeken is számos referenciával rendelkezünk.[line divide]Siemens rendszerintegrátorként *S7-1200/1500 *PLC és *WinCC *SCADA alapú irányítástechnikai rendszereket fejlesztünk.[line divide]Felhasználói igény esetén *Omron és *Rockwell automatika rendszerek fejlesztésében és üzemeltetésében is közreműködünk.[line divide]A mára elavulttá váló *SattControl vezérlések Siemens migrációjában rendkívül nagy tapasztalattal rendelkezünk.[line divide]Speciális rendszerfunkciók- és követelmények (pl. *safety ) teljesítése esetén is közreműködünk a fejlesztések során.[line divide]*Robotizációs igények esetén is készséggel állunk rendelkezésre."],
        },
        {
            title: "OT hálózat-tervezés",
            text: ["Ipari sztenderdek alapján, többnyire a *Siemens gyári megoldásait alkalmazva segítünk megbízóinknak az OT hálózatok tervezésében és kivitelezésében.[line divide]Projektportfóliónk az egyszerű csillagpontos topológiáktól az *összetettebb *optikai *hálózatokig is kiterjed.[line divide]Segítséget tudunk továbbá nyújtani a Siemens gyári alkalmazásait használva *VPN-alapú *távelérések kiépítésében, mely a termelés üzembiztonságának elengedhetetlen feltétele.[line divide]Az uniós *NIS2 *irányelv kapcsán és egyéb szabályozási kérdésben szakértelemmel segítjük ügyfeleinket az eligazodásban, biztosítjuk a szabályozásoknak való megfelelést és a működési rugalmasságot."],
        },
        {
            title: "Adatgyűjtés és feldolgozás",
            text: ["SCADA rendszereinket folyamatosan fejlesztjük az iparági trendeknek és az állandóan bővülő felhasználói igényeknek megfelelően.[line divide]Termelési felügyelet és mérésadatgyűjtés mellett mára kiemelt fontosságú cél a gyártás során keletkező adatok hatékony és optimális feldolgozása, valamint az egyszerű és informatív prezentálása. *SCADA rendszereink a kibővített eseménynaplózás, *OEE adatgyűjtés és *energiamenedzsment modulokkal a fenti kihívásokban segítenek.[line divide]Applikációink fejlesztése során előnyben részesítjük a Siemens platformjait: WinCC V8, WinCC Unified, Industrial Edge, stb."],
        },
        {
            title: "Szerviz és karbantartás",
            text: ["Az elektromos ipar jelentős fejlődésen ment keresztül, amely *minimális *alkatrészmeghibásodási *arányt eredményezett. Ennek ellenére a rendszeres ellenőrzés és karbantartás továbbra is kulcsfontosságú a zavartalan működéshez.[line divide]*Testreszabható *karbantartási *szerződéseket és távoli szoftveres támogatást is kínálunk.[line divide]Szolgáltatásaink közé tartozik a helyi karbantartó személyzet képzése, a probléma gyors helyszíni megoldása, az állásidő minimalizálása, és a rendszer teljesítményének optimalizálása."],
        }
    ]
}

export default content;