import React from "react";
import "../../styles/components/icon-container.css";
import { content } from "../../locales/icon-text-map";

function Icon({ lang, type, icon}) {

    return (<div key={`${icon}`} className="icon-sub-container d-flex flex-row position-relative">
        <img className={`icon-item d-flex`} src={`/images/icons/${type}/${icon}.png`} />
        <span className={`icon-item-text align-self-end`} style={{textAlign: "center"}}>{content[icon][lang]}</span>
    </div>);
}

export default Icon;