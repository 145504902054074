import React from "react";
import Icon from "./Icon";
import "../../styles/components/icon-container.css";
import { colors } from "../../styles/colorPalette";

function IconContainer({ lang, type, icons }) {

    return (
        <div className={`icon-container d-flex flex-row p-auto m-auto mb-4 justify-content-center`} id={`icon-container`} style={{flexWrap: "wrap"}}>
            <span style={{alignSelf: "center", fontWeight: "bold", color: colors.grey.dark}} className="d-flex position-relative">{lang === "hu" ? "Szakterületeink" : "Industries in focus"}</span>
            <span className={`d-flex flew-row justify-content-start `} style={{overflowX: "auto"}}>{icons.map(icon => <Icon key={icon} lang={lang} type={type} icon={icon}/>)}</span>
        </div>
    );
}

export default IconContainer;