import React from "react";
import "../../styles/components/accordion.css";
import { colors } from "../../styles/colorPalette";

function Accordion({ theme, contents }) {
    const getIcon = (index, subIndex, image) => image && (<img key={`list-item-img-${image}-${index}-${subIndex}`} alt="Logo" className="accordion-icon d-flex" src={`/images/icons/trans-grey/${image}.png`} style={{ height: "40px" }} />);

    const handleItems = (theme, texts, image, index) => {
        const otherIndex = index;
        return (
            <ul className="list-group list-group-flush">
                {texts.flatMap(t => t.split("[line divide]")).map((text, index) => (<li className="list-group-item" key={`list-item-${theme}-${otherIndex}-${index}`} style={{color: colors.grey.black}}>{handlePureText(text)}</li>))}
                {image &&
                    <li className="list-group-item list-item-img d-flex flex-row p-3" key={`list-img-${theme}-${otherIndex}-${index}`} style={{borderTop: `0.1rem solid ${colors.black}`, overflowX: "auto"}}>
                        {image.map(img => getIcon(otherIndex, index, img))}
                    </li>
                }
            </ul>
        );

    }

    const handlePureText = (txt) => {
        return txt.split(/(\s+)/).map(w => w.includes("*") ? <strong key={`${w}`}>{w.replace("*", "")}</strong> : w);
    }

    return (
        <div className="accordion d-flex flex-column p-auto m-auto mb-4" id={`accordion-${theme}`}>
            {contents.map((content, index) => {
                return (
                    <div className="accordion-item" key={`accordion-item-${theme}-${index}`}>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" style={{ fontSize: "125%" }} type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapse-${theme}-${index}`} aria-expanded="false" aria-controls={`panelsStayOpen-collapse-${theme}-${index}`}>
                                {content.title}
                            </button>
                        </h2>
                        <div id={`panelsStayOpen-collapse-${theme}-${index}`} className="accordion-collapse collapse">
                            <div className="accordion-body">
                                {handleItems(theme, content.text, content.image, index)}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default Accordion;