export const content = {
    "hu": {
        founded: "Alapítva: ",
        registry: "Cégjegyzékszám:",
        tax: "Adószám:",
        hqTitle: "Székhely:",
        hqValue: "2536 Nyergesújfalu, Kossuth Lajos utca 220/A.",
        activityTitle: "Főtevékenység:",
        activityValue: "3320 Ipari gép, berendezés üzembe helyezése"
    },
    "en": {
        founded: "Founded: ",
        registry: "Company Registry No.:",
        tax: "Tax Identifier",
        hqTitle: "Headquarters:",
        hqValue: "Kossuth Lajos utca 220/A., Nyergesújfalu, 2536 Hungary",
        activityTitle: "Activity:",
        activityValue: "3320 Industrial machinery, implementation"
    }
}