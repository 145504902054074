import React, {useState, useEffect} from 'react';
import Navigation from './Navigation';
import '../styles/components/header.css';
import { colors } from '../styles/colorPalette';

function Header({language}) {
  const [headerClass, setHeaderClass] = useState("");
  const [stickyTop, setStickyTop] = useState("sticky-top");
  const [logo, setLogo] = useState("logo-no-data");
  const [flexClass, setFlexClass] = useState("d-flex");

  useEffect(() => {
    const handleScroll = () => window.scrollY === 0 ? setHeaderClass("") : setHeaderClass("header-shadow");
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth < 984 ? setStickyTop("") : setStickyTop("sticky-top");
      window.innerWidth < 984 ? setLogo("icon") : setLogo("logo-no-data");
      window.innerWidth < 984 ? setFlexClass("d-flex flex-column mx-auto") : setFlexClass("d-flex");
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
      <header className={`header ${headerClass} ${stickyTop} d-flex flex-row justify-space-between`} style={{backgroundColor: colors.white}} >
        <Navigation language={language} logo={logo} flexClass={flexClass}/>
      </header>
    );
  }
  
export default Header;