const content = {
    en: {
        "about": "About us",
        "portfolio": "Our work",
        "contact": "Contact",
        "terms": "Funding",
        "legal": "Legal",
        "docs": "Company policy & GDPR"
    },
    hu: {
        "about": "Rólunk",
        "portfolio": "Munkáink",
        "contact": "Kapcsolat",
        "terms": "Támogatási információk",
        "legal": "Impresszum",
        "docs": "Cégpolitika és GDPR"
    }
}

export default content;