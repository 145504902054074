import React from 'react';
import BaseComponent from '../BaseComponent';
import "../../styles/components/plane.css";
import { content } from '../../locales/legal';
import { useParams } from 'react-router-dom';

function Legal({manualLanguage}) {

  const { lang } = useParams();
  const language = lang || manualLanguage;
  return (
    <>
      <BaseComponent language={language}>
        <section className={`container-fluid plane d-flex flex-column`} style={{ width: "70%" }}>
            <h2 style={{ fontSize: "1.5em" }}>KOB-FLEX KFT.</h2>
            <p><b>KOB-FLEX Irányítástechnikai, Automatizálási Szolgáltató Korlátolt Felelősségű Társaság</b></p>
            <p>{content[language].founded} 2005.01.27.</p>
        </section>
        <section className="container-fluid plane d-flex flex-column justify-content-center p-3 mb-5" style={{ width: "70%" }}>
          <table class="table table-hover mb-3">
            <tbody>
            <tr>
                <th scope="row">{content[language].registry}</th>
                <td>11-09-010640</td>
              </tr>
              <tr>
                <th scope="row">{content[language].tax}</th>
                <td>13442415-2-11</td>
              </tr>
              <tr>
                <th scope="row">{content[language].activityTitle}</th>
                <td>{content[language].activityValue}</td>
              </tr>
              <tr>
                <th scope="row">{content[language].hqTitle}</th>
                <td colspan="2">{content[language].hqValue}</td>
              </tr>
            </tbody>
          </table>
        </section>
      </BaseComponent>
    </>
  );
}

export default Legal;